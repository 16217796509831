import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/image/logo_myculture.png'


const _hoisted_1 = {
  class: "body-width-reponsive",
  style: {"max-width":"1200px"}
}
const _hoisted_2 = { class: "page-head-menu" }
const _hoisted_3 = { class: "mx-auto" }
const _hoisted_4 = {
  key: 0,
  class: "company-logo-image-container",
  style: {"display":"inline"}
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  src: _imports_0,
  alt: "Happily logo",
  class: "company-logo-image",
  style: {"width":"18rem"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_card_reults = _resolveComponent("main-card-reults")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.companyLogoUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("img", {
                src: _ctx.companyLogoUrl,
                alt: "",
                class: "company-logo-image image"
              }, null, 8, _hoisted_5)
            ]))
          : (_openBlock(), _createElementBlock("img", _hoisted_6))
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_main_card_reults, {
        "disabled-rating": _ctx.disabledRating,
        "is-respondent": true
      }, null, 8, ["disabled-rating"])
    ])
  ]))
}